<!-- Confirmation of email address and creation op password after registration of stream user on dashboard -->

<template>
    <v-card class="pa-3" max-width="800">
        <h1>{{ $t("confirmEmailAddPassword.title") }}</h1>
        
        <!-- Form -->
        <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
        >
            
            <!-- Password -->
            <v-text-field
                v-model="form.password"
                :label="$t('confirmEmailAddPassword.password')"
                name="password"
                required
                :type="showPassword ? 'text' : 'password'" 
                :rules="rules.password"
                autocomplete="off"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="() => (showPassword = !showPassword)"
                clearable
            ></v-text-field>

            <v-text-field
                v-model="form.passwordConfirm"
                :label="$t('confirmEmailAddPassword.passwordConfirm')"
                name="passwordConfirm"
                required
                :type="showPasswordConfirm ? 'text' : 'password'" 
                :rules="rules.passwordConfirm"
                autocomplete="off"
                :append-icon="showPasswordConfirm ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="() => (showPasswordConfirm = !showPasswordConfirm)"
                clearable
            ></v-text-field>
            
            <!-- Terms and conditions & Privacy -->
            <v-checkbox 
                v-model="form.terms" 
                :rules="rules.terms"
                required
            >
                <template v-slot:label>
                    <div>
                        {{ $t("confirmEmailAddPassword.confirm.label") }}
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <a href="https://illi-tv.nl/algemene-voorwaarden/" target="_blank" v-bind="attrs" v-on="on" @click.stop>
                                    {{ $t("confirmEmailAddPassword.confirm.terms") }}
                                </a>
                            </template>
                            {{ $t("confirmEmailAddPassword.confirm.newTab") }}
                        </v-tooltip>
                        {{ $t("confirmEmailAddPassword.confirm.and") }}
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <a href="https://illi-tv.nl/privacy/" target="_blank" v-bind="attrs" v-on="on" @click.stop>
                                    {{ $t("confirmEmailAddPassword.confirm.privacy") }}
                                </a>
                            </template>
                            {{ $t("confirmEmailAddPassword.confirm.newTab") }}
                        </v-tooltip>
                    </div>
                </template>
            </v-checkbox>


            <v-btn :disabled="!valid" color="primary" type="submit">{{ $t("confirmEmailAddPassword.submit") }}</v-btn>

        </v-form>
        
        <!-- Feedback -->
        <v-snackbar v-model="feedback.active" :timeout="2000" :color="feedback.color">
            <strong>{{ feedback.message }}</strong>
        </v-snackbar>

    </v-card>
</template>

<script>
import Vue from "vue";
import {ILLI_API_SERVER_URL } from "../config";
import axios from "axios";
import { delay } from "../utils/utils";

export default Vue.extend({
    data() {
        return {
            valid: true,
            form: {
                password: null,
                passwordConfirm: null,
                terms: false
            },
            showPassword: false,
            showPasswordConfirm: false,
            rules: {
                password: [
                    v => !!v || 'Wachtwoord is verplicht.',
                    v => v?.length >= 8 || 'Wachtwoord moet minimaal 8 tekens lang zijn.'
                ],
                passwordConfirm: [v => v == this.form.password || 'Wachtwoord moet overeenkomen.'],
                terms: [v => !!v || 'U dient akkoord te gaan met de algemene voorwaarden en privacyverklaring.']
            },
            feedback: {
                active: false,
                message: '',
                color: ''
            }
        };
    },
    methods: {

        submit() {
            console.warn('SUBMIT');

            this.valid = this.$refs.form.validate();
            
            if (this.valid) {

                const token = this.$route.params.token;
                const password = this.form.password;

                axios.post(`${ILLI_API_SERVER_URL}/stream/account/open/activate`, { token, password })
                .then((response) => {
                    console.log({response});
                    this.feedback.message = 'Account geactiveerd!';
                    this.feedback.color = 'success';
                    this.feedback.active = true;
                    delay(2000).then(() => {
                        this.$router.push({ name: 'login' });
                    });
                })
                .catch((error) => {
                    console.log({error});
                    this.feedback.message = 'Er ging iets fout. Probeer het later nog eens.';
                    this.feedback.color = 'red';
                    this.feedback.active = true;
                });

            } else {
                console.warn("Form is invalid.");
            }
        }

    }
})

</script>

<style scoped>



</style>